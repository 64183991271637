import React, { FC, useContext } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

type SeparatorProps = {
	themed?: boolean
}

export const Separator: FC<SeparatorProps & BoxProps> = ({ themed = false, ...props }) => {
	const [theme] = useContext(ColorThemeContext);

	return (
		<Box
			as="span"
			position="relative"
			fontSize={["lg", null, null, "xl"]}
			fontWeight="300"
			display="inline-block"
			width="2px"
			height="auto"
			overflow="hidden"
			_before={{
				content: "''",
				position: "absolute",
				bgColor: themed ? theme.colors.ribbon.color : "primary.text",
				w: "2px",
				h: "2px",
				top: "50%",
				left: "50%",
				transform: "translateY(-50%) translateX(-50%)",
			}}
			{...props}
		/>
	)
};